import HubSpotForm from "./investor";
import "./invest.css"
const Investor = () => {
    return ( <div className="investor-box bg-black ">
        <img src="/images/logoinvest.svg" alt="" />
        <div className="head-inv">Invest in Our Time-Sharing Marketplace
        </div>
        <div className="inv-body">Interested in being a part of this exciting opportunity? Be a part of our platform that connects busy people with trusted helpers for everyday errands. Invest in our rapidly growing USD 150 billion sharing economy marketplace and help us create a more convenient world for everyone.</div>
        <div className="invest-flex flex">
            <div className="inv-image-left">
                <img src="./images/errandInvest.png" alt="" />
            </div>
            <div className="hubspot-inv">
                <HubSpotForm/>
            </div>
        </div>
    </div> );
}
 
export default Investor;