import { useState } from "react";
import AgentComp from "../../Components/AgentComp";
import AgentHowItWorks from "../../Components/AgentHowItWorks";
import WhyUseHerrandAgent from "../../Components/whyUseHerrandAgent";
import Footer from "../../Layout/Footer";
import Navbar from "../../Layout/Navbar";
import Terms from "../LandingPage/Terms";
import { Privacy } from "../Privacy";
import "./style.css"
const Runner = () => {
    const [isTermsOpen, setIsTermsOpen] = useState(false);
  const [isPrivacyOpen, setIsPrivacyOpen] = useState(false);
  const handlePrivacyOpenClick = () => {
    setIsPrivacyOpen(true);
  };
  const handlePrivacyCloseClick = () => {
    setIsPrivacyOpen(false);
  };
  const handleTermsOpenClick = () => {
    setIsTermsOpen(true);
  };
  const handleTermsCloseClick = () => {
    setIsTermsOpen(false);
  };
    return ( <div className="land-relat">
        {isTermsOpen && (
        <div className="land-lay flex">
          <Terms handleTermsCloseClick={handleTermsCloseClick} />
        </div>
      )}
      {isPrivacyOpen && (
        <div className="land-lay flex">
          <Privacy handlePrivacyCloseClick={handlePrivacyCloseClick} />
        </div>
      )}
          <Navbar />
          <div className="ptadd">
        <div className="head-runner">Become a Runner</div>
        <div className="inst-runner">Are you looking for a flexible way to earn extra income while helping others? Join Herrands as a runner and enjoy the freedom of setting your own schedule, meeting new people, and making a difference in your community.</div>
        {/* <div className="head-body">Join Herrands as a Runner!</div> */}
         
         <AgentComp />

 <AgentHowItWorks />
 <WhyUseHerrandAgent />
 <Footer
        handleTermsOpenClick={handleTermsOpenClick}
        handlePrivacyOpenClick={handlePrivacyOpenClick}
      />
 </div>
    </div> );
}
 
export default Runner;