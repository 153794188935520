
const WhyUseHerrand = () => {
    return (
        <div className="our-feature-container" id="how-it-works-section">
            <div className="our-feature-head">
                <div className="head-tit">Why Use Herrands?</div>
                <div className="rec-head"></div>
            </div>

            <div className="how-it-works-bx">
                <div className="about-section-container work">
                    
                    <div className="right-side-abou">

                        <div className="abt-body incr">
                            <p className="">
                                <span className="float-start font-bold text-[#0066f5] mr-1">
                                Your Time is Precious: 
                                </span>
                                We understand the value of your time. Our platform connects you with reliable and efficient runners who will handle your errands and give you more time to focus on what truly matters to you.
                            </p>

                            <br />

                            <p className="">
                                <span className="float-start font-bold text-[#0066f5] mr-1">
                                Stress-Free and Convenience:
                                </span>
                                Forget the hassle of running your errands all alone. Whether it's picking up groceries, dropping off packages, or attending appointments, our runners are here to make all of it easier for you. Just a few taps on your app, and your tasks are taken care of.
                            </p>

                            <br />
                            <p className="">
                                <span className="float-start font-bold text-[#0066f5] mr-1">
                                Trustworthy Service:
                                </span>
                                We prioritise your peace of mind. Our runners are thoroughly vetted and trained to ensure your errands are completed with care and professionalism. You can trust Herrands to deliver exceptional service every time.
                            </p>
                            <br />
                            <p className="">
                                <span className="float-start font-bold text-[#0066f5] mr-1">
                                Flexibility and Control:
                                </span>
                                With Herrands, you have the flexibility to schedule errands at your convenience. Need something done urgently? No problem. Prefer to plan ahead? We've got you covered. Our platform adapts to your needs.
                            </p>
                        </div>
                    </div>
                    <div className="button-cont">
                        {/* <button className="down-btn">Download the App</button> */}
                    </div>
                </div>
            </div>
        </div>);
}

export default WhyUseHerrand;