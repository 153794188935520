import { useState } from "react";

const DownComp = () => {
  const [showPopover, setShowPopover] = useState(false);

  const handleImageClick = () => {
    setShowPopover(true);
    // Hide the popover after 3 seconds (optional)
    setTimeout(() => setShowPopover(false), 3000);
  };

  const handleRedirect = () => {
    window.open('https://play.google.com/store/apps/details?id=com.herrands', '_blank', 'noopener,noreferrer');
  };
  return (
    <div className="dow-main-box" id="download-section">
      <div className="download-comp-container">
        <div className="left-bx-con ">
          <div className="fonsx">
            More time. Less stress. Limitless possibilities.
          </div>
          <div className="instruct newm">
            Ready to make your life easier? Download our app now and
            start enjoying the benefits of time-sharing. Your journey to a
            more effective way of pursuing your dreams, passions, and goals with
            renewed vigour begins here.
          </div>
          {/* <div className="app-badge-dow">
            <img src="images/bad1.png" alt="" />

            <img src="images/bad2.png" alt="" />
          </div> */}
        </div>
        <div className="download-ri new-downlad-box ">
          {/* <img src="images/qr.png" alt="" /> */}
          {/* <img src="images/land4.png" alt="" /> */}
          <div className="app-badge-download">
            <div className="apple-down-bx">
            
              <div className="for-app-sroid">Download for iOS</div>
              <div className="relative  ">
          {showPopover && (
                  <div className="absolute top-[-40px] left-0 w-[100%]  h-[40px] p-2 bg-[#F0F0F0] text-black text-[20px] text-center rounded shadow-lg">
                    Coming Soon!
                  </div>
                )}
              <img src="images/bad1.png" alt=""  onClick={handleImageClick}/>
              </div>
            </div>
            <div className="apple-down-bx">
              <div className="for-app-sroid">Download for Android</div>
              <img src="images/bad2.png" alt="" onClick={handleRedirect}/>
            </div>
            {/* <img src="images/qr.png" alt="" className="qr-ma" /> */}
          </div>
        </div>
        <div className="trd-side">
          <img src="/images/trd.png" alt="" />
        </div>
      </div>
    </div>
  );
};

export default DownComp;
