
const WhyUseHerrandAgent = () => {
    return (
        <div className="our-feature-container" id="how-it-works-section">
            <div className="our-feature-head">
                <div className="head-tit">Why Use Herrands?</div>
                <div className="rec-head"></div>
            </div>

            <div className="how-it-works-bx">
                <div className="about-section-container work">
                    
                    <div className="right-side-abou">

                        <div className="abt-body incr">
                            <p className="">
                                <span className="float-start font-bold text-[#0066f5] mr-1">
                                Make Money When You Like:
                                </span>
                                Herrands empowers you to earn extra income by using your free time effectively. Whether you're a student, a part-time worker, or just looking for additional income, becoming a runner with Herrands offers you a flexible and rewarding opportunity.
                            </p>

                            <br />

                            <p className="">
                                <span className="float-start font-bold text-[#0066f5] mr-1">
                                Be Your Own Boss:
                                </span>
                                As a runner, you have the freedom to choose when and where you work. Take control of your schedule and work at your own pace. We give you the autonomy to balance your work with your personal life.
                            </p>

                            <br />
                            <p className="">
                                <span className="float-start font-bold text-[#0066f5] mr-1">
                                Make a Difference:
                                </span>
                                By helping others with their errands, you're making a positive impact in their lives. Your contribution allows senders to save time, reduce stress, and enjoy greater convenience. Your efforts are truly valued.
                            </p>
                            <br />
                            <p className="">
                                <span className="float-start font-bold text-[#0066f5] mr-1">
                                Support and Community:
                                </span>
                                Herrands is more than just a platform; it's a community. We provide ongoing support, resources, and opportunities for runners to thrive. Join a network of like-minded individuals dedicated to making life easier for others.
                            </p>
                        </div>
                    </div>
                    <div className="button-cont">
                        {/* <button className="down-btn">Download the App</button> */}
                    </div>
                </div>
            </div>
        </div>);
}

export default WhyUseHerrandAgent;