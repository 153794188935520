import React, { useEffect } from 'react';

const HubSpotForm = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "//js.hsforms.net/forms/embed/v2.js";
    script.type = "text/javascript";
    script.charset = "utf-8";
    script.async = true;
    
    script.onload = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "7520456",
          formId: "ff93ea53-243b-4836-91c7-69f3ce73bb0f",
          target: '#hubspotForm'
        });
      }
    };

    document.body.appendChild(script);
  }, []);

  return <div id="hubspotForm" className='ccc'></div>;
};

export default HubSpotForm;
